<template>
  <div>
    <component
      :is="getComponent(rawData.entityClass)"
      v-bind="{ data, rawData }"
      @click="handleClick"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'DynamicTask',
  emits: ['click'],
  components: {
    DynamicDefaultCollection: defineAsyncComponent(() => import('./dynamic-default-collection.vue')),
    DynamicSequenceCollection: defineAsyncComponent(() => import('./dynamic-sequence-collection.vue')),
    DynamicProductsCollection: defineAsyncComponent(() => import('./dynamic-products-collection.vue')),
    DynamicAssemblageTasksCollection: defineAsyncComponent(() => import('./dynamic-assemblage-tasks-collection.vue')),
    DynamicTasksCollection: defineAsyncComponent(() => import('./dynamic-tasks-collection.vue')),
    DynamicMessageCollection: defineAsyncComponent(() => import('./dynamic-message-collection.vue')),
    DynamicPlaceCollection: defineAsyncComponent(() => import('./dynamic-place-collection.vue')),
    DynamicStorageItemsCollection: defineAsyncComponent(() => import('./storage-items-collection.vue')),
    DynamicAcceptanceCollection: defineAsyncComponent(() => import('./dynamic-acceptance-collection.vue')),
    DynamicOfferCollection: defineAsyncComponent(() => import('./dynamic-offer-collection.vue')),
    OptionsCollection: defineAsyncComponent(() => import('./options-collection.vue')),
    DynamicInventoryCollection: defineAsyncComponent(() => import('./dynamic-inventory-collection.vue')),
    OrderObject : defineAsyncComponent(() => import('./order-object.vue'))
  },
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      components: {
        'default': 'dynamic-default-collection',
        'Orderadmin\\Storage\\Entity\\Movement\\Acceptance': 'dynamic-acceptance-collection',
        'Orderadmin\\Storage\\Tasks\\Objects\\Message': 'dynamic-message-collection',
        'Orderadmin\\Storage\\Entity\\Tasks\\Task': 'dynamic-tasks-collection',
        'Orderadmin\\Storage\\Entity\\Assemblage\\OrderProductTask': 'dynamic-products-collection',
        'Orderadmin\\Storage\\Entity\\Assemblage\\Task': 'dynamic-assemblage-tasks-collection',
        'Orderadmin\\Storage\\Entity\\AbstractPlace': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Item': 'dynamic-storage-items-collection',
        'Orderadmin\\Products\\Entity\\Product\\Offer': 'dynamic-offer-collection',
        'Orderadmin\\Storage\\Tasks\\Objects\\Option': 'options-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Row': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Section': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Place\\Room': 'dynamic-place-collection',
        'Orderadmin\\Storage\\Entity\\Movement\\Inventory': 'dynamic-inventory-collection',
        'Orderadmin\\Storage\\Entity\\Tasks\\Sequence': 'dynamic-sequence-collection',
        'Orderadmin\\Products\\Entity\\Order': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'order-object',
        'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'order-object',
      }
    }
  },
  methods: {
    handleClick (data) {
      this.$emit('click', data)
    },
    getComponent (component) {
      if (this.components[component]) {
        return this.components[component]
      } else {
        return this.components['default']
      }
    }
  }
}
</script>
